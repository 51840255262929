import axios from 'axios'

export const API_HS_NI_ENDPOINT = process.env.REACT_APP_API_HS_NI_ENDPOINT

export const OUT_OF_HOURS_ACTIONS = {
  send: 'send',
  abort: 'abort'
}

const notificacoesInteligentes = {}

notificacoesInteligentes.getQrCode = async (api, secret) => {
  const response = await axios.post(`${API_HS_NI_ENDPOINT}/instances/microservices/generateQRCode`, {
    clientApi: api,
    clientSecret: secret
  })

  return response?.data
}

notificacoesInteligentes.getInformations = async (api, secret) => {
  const response = await axios.post(`${API_HS_NI_ENDPOINT}/instances/microservices/informations`, {
    clientApi: api,
    clientSecret: secret
  })

  return response?.data
}

notificacoesInteligentes.getMessages = async (api, secret, { offset = 0, limit = 30 } = {}) => {
  const response = await axios.get(`${API_HS_NI_ENDPOINT}/messages/microservices/messages`, {
    params: {
      api,
      secret,
      offset,
      limit
    }
  })

  return response?.data || []
}

notificacoesInteligentes.getMessagesStatistics = async (api, secret, { offset = 0, limit = 30000 } = {}) => {
  const response = await axios.get(`${API_HS_NI_ENDPOINT}/messages/microservices/messageStatistics`, {
    params: {
      api,
      secret,
      offset,
      limit
    }
  })

  return response?.data?.statistics
}

notificacoesInteligentes.sendMessage = async (api, secret, { message, phone, fileUrl } = {}) => {
  const response = await axios.post(`${API_HS_NI_ENDPOINT}/messages/sendMessage`, {
    clientApi: api,
    clientSecret: secret,
    message: encodeMessage(message, fileUrl),
    phone
  })

  return response?.data
}

notificacoesInteligentes.resendMessage = async (messageId, api, secret) => {
  const response = await axios.post(`${API_HS_NI_ENDPOINT}/messages/microservices/resendMessage/${messageId}`, {
    clientApi: api,
    clientSecret: secret
  })

  return response?.data
}

notificacoesInteligentes.deleteMessage = async (messageId, api, secret) => {
  const response = await axios.delete(`${API_HS_NI_ENDPOINT}/messages/microservices/delete/${messageId}`, {
    clientApi: api,
    clientSecret: secret
  })

  return response?.data
}

notificacoesInteligentes.changeOfficeHour = async (api, secret, { isEnabled, outOfHoursAction = 'send', days = [] } = {}) => {
  if(![OUT_OF_HOURS_ACTIONS.abort, OUT_OF_HOURS_ACTIONS.send].includes(outOfHoursAction)) {
    throw new Error('outOfHoursAction field invalid')
  }

  const response = await axios.put(`${API_HS_NI_ENDPOINT}/instances/microservices/changeOfficeHourService`, {
    clientApi: api,
    clientSecret: secret,
    officeHourService: {
      active: isEnabled,
      outOfHoursAction,
      days
    }
  })

  return response?.data
}

notificacoesInteligentes.expireQrCode = async (api, secret) => {
  const response = await axios.post(`${API_HS_NI_ENDPOINT}/instances/microservices/expireQRCode`, {
    clientApi: api,
    clientSecret: secret
  })

  return response?.data
}

notificacoesInteligentes.restartApi = async (api, secret) => {
  const response = await axios.post(`${API_HS_NI_ENDPOINT}/instances/microservices/reboot`, {
    clientApi: api,
    clientSecret: secret
  })

  return response?.data
}

notificacoesInteligentes.clearQueue = async (api, secret) => {
  const response = await axios.delete(`${API_HS_NI_ENDPOINT}/instances/microservices/clearQueue`, {
    data: {
      clientApi: api,
      clientSecret: secret
    }
  })

  return response?.data
}

notificacoesInteligentes.clearReport = async (api, secret) => {
  const response = await axios.delete(`${API_HS_NI_ENDPOINT}/messages/microservices/clearHistoric`, {
    data: {
      clientApi: api,
      clientSecret: secret
    }
  })

  return response?.data
}

notificacoesInteligentes.resendMessagesWithErrors = async (api, secret) => {
  const response = await axios.post(`${API_HS_NI_ENDPOINT}/messages/microservices/resendMessageError`, {
    clientApi: api,
    clientSecret: secret
  })

  return response?.data
}

notificacoesInteligentes.deleteMessagesWithErrors = async (api, secret) => {
  const response = await axios.post(`${API_HS_NI_ENDPOINT}/messages/microservices/clearErrors`, {
    clientApi: api,
    clientSecret: secret
  })

  return response?.data
}

notificacoesInteligentes.changeDisconnectionNotificationNumber = async (api, secret, phone) => {
  const response = await axios.put(`${API_HS_NI_ENDPOINT}/instances/microservices/changeDisconnectionNotificationNumber`, {
    clientApi: api,
    clientSecret: secret,
    disconnectionNotificationNumber: phone
  })

  return response?.data
}

notificacoesInteligentes.changeDailyReportNotificationNumber = async (api, secret, phone) => {
  const response = await axios.put(`${API_HS_NI_ENDPOINT}/instances/microservices/changeDailyReportNotificationNumber`, {
    clientApi: api,
    clientSecret: secret,
    dailyReportNumber: phone
  })

  return response?.data
}

notificacoesInteligentes.changeMessageTimer = async (api, secret, { timerOverride = undefined, timerNewLine = undefined } = {}) => {
  const response = await axios.post(`${API_HS_NI_ENDPOINT}/instances/microservices/setTimerOverride`, {
    clientApi: api,
    clientSecret: secret,
    timerOverride,
    timerNewLine
  })

  return response?.data
}

// Public endpoints
notificacoesInteligentes.getNotifications = async () => {
  const response = await axios.get(`${API_HS_NI_ENDPOINT}/notifications`)
  return response?.data
}

// Helpers
function encodeMessage(message, fileUrl) {
  const filename = getFilenameByUrl(fileUrl)
  const filenameTag = filename === '' ? '' : `%Filename%${filename}%/Filename%`

  return fileUrl === ''
    ? message
    : `${message}%SendFile%${filenameTag}%Url%${fileUrl.split(/\s/).join('')}%/Url%%/SendFile%`
}

function getFilenameByUrl(url) {
  return url ? url.substring(url.lastIndexOf('/') + 1) : ''
}

export default {
  notificacoesInteligentes
}
