import React from 'react'
import clsx from 'clsx'
import { Paper, useTheme } from '@mui/material'
import LayoutMain from '../layouts/Main'
import LayoutBase from '../layouts/Base'
import Text from '../components/Text'
import { Link } from 'react-router-dom'
import Logo from '../components/Logo'

export default function ChangelogPage() {
  const theme = useTheme()

  return (
    <LayoutBase>
      <LayoutMain>
        <div className="relative w-full py-14">
          <div
            className={clsx('absolute top-0 left-0 w-full h-[400px] ', {
              'bg-opacity-[0.05] bg-white': theme.isDark,
              'bg-opacity-[0.05] bg-gray-500': !theme.isDark
            })}
          />
          <div
            className={clsx('absolute top-0 left-0 w-full h-[550px]', {
              'bg-opacity-[0.05] bg-white': theme.isDark,
              'bg-opacity-[0.05] bg-gray-500': !theme.isDark
            })}
          />
          <div className="relative py-12 md:py-32">
            <div className="mx-auto w-full md:max-w-[950px] px-2 md:px-12">
              <Text className="text-2xl mb-6">Changelog</Text>
              <div className="space-y-12">
                <Paper elevation={3}>
                  <div className="p-6">
                    <div className="border-b border-[#6f6f6f4c] pb-6">
                      <Text className="font-black text-2xl">
                        Mensagem PIX
                      </Text>
                      <TagFeature />
                    </div>
                    <div className="mt-8">
                      <div className="flex items-center justify-center space-x-5">
                        <Logo className="w-[200px]" />
                        <span className="font-black text-4xl">+</span>
                        <div>
                          <img
                            src={`/assets/images/${window.localStorage.getItem('preferredTheme') === 'dark' ? 'hiperpay-logo-primary-text-white.png' : 'hiperpay-logo-primary.png'}`}
                            className="w-[180px]"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-8">
                      <Text>
                        Com a HiperPay liberamos a possibilidade dos contatos ter uma melhor experiência ao receber copia e cola, através de um link encurtado enviado ao contato onde ele irá abrir uma página facilitando o pagamento através de pix e outros meios de pagamento.
                      </Text>
                      <Text>
                        Para mais detalhes sobre a implementação da mensagem pix, acesse a página da nova documentação em <Link className="font-black" to="/documentation#message-pix">Mensagem Pix.</Link>
                      </Text>
                    </div>
                    <div>
                      <img src="/assets/images/hipersend-doc-3.png" className="w-full" />
                    </div>
                  </div>
                </Paper>
                <Paper elevation={3}>
                  <div className="p-6">
                    <div className="border-b border-[#6f6f6f4c] pb-6">
                      <Text className="font-black text-2xl">
                        Nova documentação detalhada da API
                      </Text>
                      <TagFeature />
                    </div>
                    <div className="mt-8">
                      <Text>
                        Agora é possível consultar a documentação detalhada da api, com diversos exemplos de como realizar a implementação em cenários diferentes.
                      </Text>
                      <div className="mb-8">
                        <img src="/assets/images/hipersend-doc.png" className="w-full" />
                      </div>
                      <Text>
                        Também é possível ter uma representação visual de como as mensagens podem ser implementadas em conjunto, pra formar um fluxo completo de acordo com diversas necessidades.
                      </Text>
                      <Text>
                        Para mais detalhes e informações, acesse a página da nova <Link className="font-black" to="/documentation">documentação.</Link>
                      </Text>
                      <div className="mb-8">
                        <img src="/assets/images/hipersend-doc-2.png" className="w-full" />
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </LayoutMain>
    </LayoutBase>
  )
}

function TagFeature() {
  return (
    <div className="table bg-emerald-600 font-black text-white px-1">FEATURE</div>
  )
}
