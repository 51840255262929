import React, { useEffect, useRef, useState } from 'react'
import anime from 'animejs'
import delay from 'delay'
import { Link, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ScienceIcon from '@mui/icons-material/Science'
import FolderIcon from '@mui/icons-material/Folder'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LayoutMain from '../layouts/Main'
import LayoutBase from '../layouts/Base'
import imageHsAirplanes from '../assets/hs-airplanes-001.png'
import {
  MESSAGE_PIX_TEMPLATE,
  MESSAGE_SIMPLE_TEMPLATE,
  MESSAGE_FILE_TEMPLATE,
  MESSAGE_FILE_AND_TEXT_TEMPLATE,
  MESSAGE_SPLIT_TEMPLATE } from '../libs/apiDocumentation'
import Text from '../components/Text'
import Logo from '../components/Logo'
import PhoneDevice from '../components/PhoneDevice'
import TestSendMessageModal from '../components/TestSendMessageModal'
import Code from '../components/Code'

export default function DocumentationPage() {
  const asideMenuRef = useRef()
  const airplanesRef = useRef()

  const [userApi, setUserApi] = useState('')
  const [openTestSendMessageModal, setOpenTestSendMessageModal] = useState(false)
  const [apiTestData, setApiTestData] = useState({
    title: '',
    template: '',
    httpMethod: ''
  })

  const handleAsideMenuAnimation = async () => {
    await delay(300)
    if(!asideMenuRef.current) return
    anime({
      targets: asideMenuRef.current,
      translateX: [-50, 0],
      opacity: [0, 1]
    })
  }

  const handleAirplanesAnimation = async () => {
    await delay(500)
    if(!airplanesRef.current) return
    anime({
      targets: airplanesRef.current.children,
      translateX: [-50, 0],
      translateY: [50, 0],
      delay: anime.stagger(100),
      opacity: [0, 1]
    })
  }

  const handleOpenTestSendMessageModal = () => {
    setOpenTestSendMessageModal(true)
  }

  const handleCloseTestSendMessageModal = () => {
    setOpenTestSendMessageModal(false)
  }

  const getUserApiFromStorage = () => {
    setUserApi(window.localStorage.getItem('api'))
  }

  useEffect(() => {
    handleAsideMenuAnimation()
    handleAirplanesAnimation()
    getUserApiFromStorage()
  }, [])

  return (
    <LayoutBase>
      <TestSendMessageModal
        open={openTestSendMessageModal}
        title={apiTestData?.title}
        httpMethod={apiTestData?.httpMethod}
        template={apiTestData?.template}
        onClose={() => setOpenTestSendMessageModal(false)}
      />
      <LayoutMain>
        <div className="flex h-full">
          <div className="hidden md:block w-[300px] h-full">
            <div ref={asideMenuRef} className="opacity-0 fixed w-[300px] h-full">
              <Paper className="h-full w-full" elevation={0}>
                <div className="w-full h-full shadow-[5px_0px_10px_0px_rgba(0,0,0,0.3)]">
                  <div className="h-[115px] px-8 pt-4 pb-6 pt-8">
                    <Text className="text-xl font-medium">Documentação</Text>
                    <Text>Guia para integração</Text>
                  </div>
                  <div>
                    <div>
                      <Text className="px-8 py-4 text-xl font-medium">
                        Integração
                      </Text>
                      <MenuList>
                        <MenuItem>
                          <ListItemIcon className="px-4">
                            <FolderIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              Envio de mensagem
                            </Text>
                          </ListItemText>
                        </MenuItem>
                        <MenuItem component={Link} href="#message">
                          <ListItemIcon className="pr-4 pl-6">
                            <SubdirectoryArrowRightIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              Mensagem simples
                            </Text>
                          </ListItemText>
                        </MenuItem>
                        <MenuItem component={Link} href="#message-file">
                          <ListItemIcon className="pr-4 pl-6">
                            <SubdirectoryArrowRightIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              Mensagem com arquivo
                            </Text>
                          </ListItemText>
                        </MenuItem>
                        <MenuItem component={Link} href="#message-split">
                          <ListItemIcon className="pr-4 pl-6">
                            <SubdirectoryArrowRightIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              Mensagem com split
                            </Text>
                          </ListItemText>
                        </MenuItem>
                        <MenuItem component={Link} href="#message-pix">
                          <ListItemIcon className="pr-4 pl-6">
                            <SubdirectoryArrowRightIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              Mensagem PIX <span className="px-1 py-0 font-black text-xs text-white bg-[#02b7fb]">NOVO</span>
                            </Text>
                          </ListItemText>
                        </MenuItem>
                        {/* <MenuItem to="/">
                          <ListItemIcon className="pr-4 pl-6">
                            <SubdirectoryArrowRightIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              Playground
                            </Text>
                          </ListItemText>
                        </MenuItem> */}
                      </MenuList>
                    </div>
                    {/* <div className="mt-3">
                      <Text className="px-8 py-4 text-xl font-medium">
                        Provedores
                      </Text>
                      <MenuList>
                        <MenuItem to="/">
                          <ListItemIcon className="px-4">
                            <FolderIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              MKAUTH
                            </Text>
                          </ListItemText>
                        </MenuItem>
                        <MenuItem to="/">
                          <ListItemIcon className="px-4">
                            <FolderIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              IXC
                            </Text>
                          </ListItemText>
                        </MenuItem>
                        <MenuItem to="/">
                          <ListItemIcon className="px-4">
                            <FolderIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              WHMCS
                            </Text>
                          </ListItemText>
                        </MenuItem>
                        <MenuItem to="/">
                          <ListItemIcon className="px-4">
                            <FolderIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              SGP
                            </Text>
                          </ListItemText>
                        </MenuItem>
                        <MenuItem to="/">
                          <ListItemIcon className="px-4">
                            <FolderIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText className="py-1">
                            <Text>
                              BEESWEB
                            </Text>
                          </ListItemText>
                        </MenuItem>
                      </MenuList>
                    </div> */}
                  </div>
                </div>
              </Paper>
            </div>
          </div>
          <div className="max-w-[1200px] mx-auto px-2 md:px-6 w-full">
            <div className="py-20">
              <div>
                <div className="flex items-end space-x-2">
                  <Logo className="w-[240px]" />
                  <Text className="text-xl font-light">
                    DEVELOPERS
                  </Text>
                </div>
                <Text className="text-xl">
                  Notificações Inteligentes
                </Text>
              </div>
              <div className="grid md:grid-cols-2">
                <div className="py-16">
                  <div className="mt-8">
                    <Text className="text-xl font-light">
                      Com a api <span className="font-medium">Notificacões Inteligentes</span> você pode efetuar envios de mensagens através de qualquer aplicação.
                    </Text>
                  </div>
                </div>
                <div ref={airplanesRef} className="relative hidden md:flex h-[200px] items-center justify-center">
                  <img src={imageHsAirplanes} className="opacity-0 absolute top-[40px] left-0 w-[60%]" />
                  <img src={imageHsAirplanes} className="opacity-0 absolute top-0 left-[150px] w-[80%]" />
                </div>
              </div>
              <div className="mb-12">
                <Text className="text-xl md:text-3xl">
                  Índice
                </Text>
                <div className="mt-4 space-y-2">
                  <Link href="#message" underline="none" className="block">
                    <div className="flex space-x-2 items-center">
                      <div className="flex items-center space-x-2">
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-[#02b7fb]">POST</span>
                        <Text>ou</Text>
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-purple-600">GET</span>
                      </div>
                      <Text component="span">
                        <ChevronRightIcon />
                      </Text>
                      <Text className="text-lg">
                        Envio de mensagem simples
                      </Text>
                    </div>
                  </Link>
                  <Link href="#message-file" underline="none" className="block">
                    <div className="flex space-x-2 items-center">
                      <div className="flex items-center space-x-2">
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-[#02b7fb]">POST</span>
                        <Text>ou</Text>
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-purple-600">GET</span>
                      </div>
                      <Text component="span">
                        <ChevronRightIcon />
                      </Text>
                      <Text className="text-lg">
                        Envio de mensagem com arquivo, usando a tag especial <span className="p-1 py-0 bg-[#02b7fb] font-black text-white">SendFile</span>
                      </Text>
                    </div>
                  </Link>
                  <Link href="#message-file" underline="none" className="block">
                    <div className="flex space-x-2 items-center">
                      <div className="flex items-center space-x-2">
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-[#02b7fb]">POST</span>
                        <Text>ou</Text>
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-purple-600">GET</span>
                      </div>
                      <Text component="span">
                        <ChevronRightIcon />
                      </Text>
                      <Text className="text-lg">
                        Envio de mensagem com arquivo e mensagem
                      </Text>
                    </div>
                  </Link>
                  <Link href="#message-split" underline="none" className="block">
                    <div className="flex space-x-2 items-center">
                      <div className="flex items-center space-x-2">
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-[#02b7fb]">POST</span>
                        <Text>ou</Text>
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-purple-600">GET</span>
                      </div>
                      <Text component="span">
                        <ChevronRightIcon />
                      </Text>
                      <Text className="text-lg">
                        Envio de mensagem com split, arquivo e mensagem, usando a tag especial <span className="p-1 py-0 bg-[#02b7fb] font-black text-white">NewLine</span>
                      </Text>
                    </div>
                  </Link>
                  <Link href="#message-pix" underline="none" className="block">
                    <div className="flex space-x-2 items-center">
                      <div className="flex items-center space-x-2">
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-[#02b7fb]">POST</span>
                        <Text>ou</Text>
                        <span className="flex justify-center w-[58px] py-0 font-black text-white bg-purple-600">GET</span>
                      </div>
                      <Text component="span">
                        <ChevronRightIcon />
                      </Text>
                      <Text className="text-lg">
                        Envio de mensagem PIX <span className="p-1 py-0 bg-[#02b7fb] font-black text-white">Pix</span>
                      </Text>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="mb-12">
                <div className="bg-[#02b7fb] bg-opacity-[0.1] rounded p-4 md:p-8 border-l-4 border-[#02b7fb]">
                  <div className="flex items-center space-x-2">
                    <LightbulbIcon className="text-[#02b7fb]" style={{ fontSize: 40 }} />
                    <Text className="font-black text-2xl text-[#02b7fb]">
                      Importante
                    </Text>
                  </div>
                  <div className="mt-2 ml-[48px]">
                    <Text className="font-medium">
                      Há duas formas de fazer envios através da api, via <span className="py-0 px-1 bg-[#02b7fb] text-white font-black">POST</span> e via <span className="py-0 px-1 bg-[#02b7fb] text-white font-black">GET</span> através de parâmetros na url. Escolha o método que melhor se adequa em sua aplicação.
                    </Text>
                  </div>
                </div>
              </div>
              {/* <div className="mb-12">
                <Text className="text-xl md:text-3xl">
                  Playground
                </Text>
                <div className="mt-4 space-y-2">
                  <div className="flex space-x-2 items-center">
                    <Text component="span">
                      <AutoAwesomeIcon className="text-[#02b7fb]" style={{ fontSize: 30 }} />
                    </Text>
                    <Text className="text-lg font-bold text-[#02b7fb]">
                      Testar e criar templates de mensagens
                    </Text>
                  </div>
                </div>
              </div> */}
              <div>
                <div className="mt-4">
                  <div className="flex items-center space-x-3">
                    <span className="flex justify-center w-[58px] font-black text-white bg-[#02b7fb]">POST</span>
                    <Text className="text-xl md:text-3xl">
                      Endpoint para envio de mensagem
                    </Text>
                  </div>
                  <div className="mt-4">
                    <div className="p-4 bg-gray-400 bg-opacity-[0.3] rounded-sm border-l-4 border-[#02b7fb]">
                      <Text className="text-xs font-light">
                        ENDPOINT
                      </Text>
                      <div className="flex items-center space-x-2">
                        <span className="flex justify-center w-[58px] font-black text-white bg-[#02b7fb]">POST</span>
                        <ChevronRightIcon className="text-[#02b7fb]" />
                        <Text className="font-medium text-lg">
                          https://myhs.app/sendMessage
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 mb-4 flex items-center space-x-2">
                    <span className="block px-2 py-0 font-black text-white bg-[#02b7fb]">BODY</span>
                      <Text className="font-medium text-xl">
                        Corpo da requisição
                      </Text>
                    </div>
                    <div>
                      <Paper elevation={5}>
                        <div className="border-l-4 border-[#02b7fb]">
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4 bg-gray-400 bg-opacity-[0.1]">
                              <div className="p-4">
                                <Text>Campo</Text>
                              </div>
                            </div>
                            <div className="col-span-8 bg-gray-400 bg-opacity-[0.1]">
                              <div className="p-4">
                                <Text>Valor</Text>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4">
                              <div className="p-4">
                                <Text className="text-lg font-medium">u</Text>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="p-4">
                                <Text>{userApi}</Text>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4">
                              <div className="p-4">
                                <Text className="text-lg font-medium">p</Text>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="p-4">
                                <Text>xxxxxxxxxxx</Text>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4">
                              <div className="p-4">
                                <Text className="text-lg font-medium">to</Text>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="p-4 flex space-x-4">
                                <Text>5511999888777</Text>
                                <span className="block px-2 py-0 font-black text-white bg-[#02b7fb]">com ddi e ddd</span>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4">
                              <div className="p-4">
                                <Text className="text-lg font-medium">msg</Text>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="p-4">
                                <Text>
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Paper>
                  </div>
                </div>
                <div className="mt-12">
                  <div className="flex items-center space-x-3">
                    <span className="flex justify-center w-[58px] font-black text-white bg-purple-600">GET</span>
                    <Text className="text-xl md:text-3xl">
                      Endpoint para envio de mensagem
                    </Text>
                  </div>
                  <div className="mt-2">
                    <div className="p-4 bg-gray-400 bg-opacity-[0.3] rounded-sm border-l-4 border-purple-600">
                      <Text className="text-xs font-light">
                        ENDPOINT
                      </Text>
                      <div className="flex items-center space-x-2">
                        <span className="flex justify-center w-[58px] font-black text-white bg-purple-600">GET</span>
                        <ChevronRightIcon className="text-purple-600" />
                        <Text className="font-medium text-lg">
                          https://myhs.app/sendMessage?app={'<app>'}&u={'<u>'}&p={'<p>'}&to={'<to>'}&msg={'<msg>'}
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 mb-4 flex items-center space-x-2">
                    <span className="block px-2 py-0 font-black text-white bg-purple-600">PARÂMETROS</span>
                      <Text className="font-medium text-xl">
                        Corpo da requisição
                      </Text>
                    </div>
                    <div>
                      <Paper elevation={5}>
                        <div className="border-l-4 border-purple-600">
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4 bg-gray-400 bg-opacity-[0.1]">
                              <div className="p-4">
                                <Text>Parâmetro</Text>
                              </div>
                            </div>
                            <div className="col-span-8 bg-gray-400 bg-opacity-[0.1]">
                              <div className="p-4">
                                <Text>Valor</Text>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4">
                              <div className="p-4">
                                <Text className="text-lg font-medium">app</Text>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="p-4">
                                <Text>
                                  webservices | mkauth | ixc | sgp | bess
                                </Text>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4">
                              <div className="p-4">
                                <Text className="text-lg font-medium">u</Text>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="p-4">
                                <Text>{userApi}</Text>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4">
                              <div className="p-4">
                                <Text className="text-lg font-medium">p</Text>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="p-4">
                                <Text>xxxxxxxxxxx</Text>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4">
                              <div className="p-4">
                                <Text className="text-lg font-medium">to</Text>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="p-4 flex space-x-4">
                                <Text>5511999888777</Text>
                                <span className="block px-2 py-0 font-black text-white bg-purple-600">com ddi e ddd</span>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 border-r-4">
                              <div className="p-4">
                                <Text className="text-lg font-medium">msg</Text>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="p-4">
                                <Text>
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Paper>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="mt-16">
                    <div className="mb-8">
                      <Text className="font-medium text-2xl">
                        Exemplos de envios
                      </Text>
                    </div>
                    <div className="space-y-12">
                      <div id="message">
                        <div>
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-[#02b7fb]">POST</span>
                            <Text className="font-medium text-xl">
                              Envio de mensagem simples
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>{MESSAGE_SIMPLE_TEMPLATE}</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const options = {
  method: 'POST',
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  },
  body: JSON.stringify({
    u: '${userApi}',
    p: 'xxxxxxxxxxx',
    to: '5511999888777',
    msg: '${MESSAGE_SIMPLE_TEMPLATE}'
  })
}

fetch(endpoint, options)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-[70%] p-2 bg-emerald-700 rounded shadow">
                                          <Text className="text-white font-medium">
                                            Olá! Sua fatura vence nos próximos 5 dias.
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem simples',
                                      template: MESSAGE_SIMPLE_TEMPLATE,
                                      httpMethod: 'POST'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>

                        {/* GET */}
                        <div className="mt-12">
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-purple-600" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-purple-600">GET</span>
                            <Text className="font-medium text-xl">
                              Envio de mensagem simples
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>Olá! Sua fatura vence nos próximos 5 dias.</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const options = {
  method: 'POST',
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  },
  body: JSON.stringify({
    u: '${userApi}',
    p: 'xxxxxxxxxxx',
    to: '5511999888777',
    msg: '${MESSAGE_SIMPLE_TEMPLATE}'
  })
}

fetch(endpoint, options)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-[70%] p-2 bg-emerald-700 rounded shadow">
                                          <Text className="text-white font-medium">
                                            {MESSAGE_SIMPLE_TEMPLATE}
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem simples',
                                      template: MESSAGE_SIMPLE_TEMPLATE,
                                      httpMethod: 'GET'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>
                        <div className="mt-8">
                          <EndpointResponseExample />
                        </div>
                      </div>
                      {/*  */}

                      <div id="message-file">
                        <div>
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-[#02b7fb]">POST</span>
                            <Text className="font-medium text-xl">
                              Envio somente do arquivo
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div class="p-4 bg-purple-600 bg-opacity-[0.1] rounded flex space-x-4">
                                        <InfoIcon style={{ fontSize: 30 }} className="text-purple-600" />
                                        <div>
                                          <Text className="font-black text-lg">Importante</Text>
                                          <Text className="font-medium">
                                            Envie o conteúdo em uma única linha, igual no exemplo a seguir
                                          </Text>
                                        </div>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>{MESSAGE_FILE_TEMPLATE}</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const options = {
  method: 'POST',
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  },
  body: JSON.stringify({
    u: '${userApi}',
    p: 'xxxxxxxxxxx',
    to: '5511999888777',
    msg: '${MESSAGE_FILE_TEMPLATE}'
  })
}

fetch(endpoint, options)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-full p-2 bg-emerald-700 rounded shadow">
                                          <div className="bg-black bg-opacity-[0.1] p-4">
                                            <div className="flex items-center space-x-2">
                                              <div className="w-[35px] h-[40px] bg-red-500 rounded flex justify-center items-center">
                                                <Text className="text-white font-black text-sm opacity-[0.5]">PDF</Text>
                                              </div>
                                              <div>
                                                <Text className="text-white">fatura.pdf</Text>
                                                <Text className="font-light text-white">PDF 13kb</Text>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem somente com arquivo',
                                      template: MESSAGE_FILE_TEMPLATE,
                                      httpMethod: 'POST'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>

                        {/* GET */}
                        <div className="mt-12">
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-purple-600" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-purple-600">GET</span>
                            <Text className="font-medium text-xl">
                              Envio somente do arquivo
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div class="p-4 bg-purple-600 bg-opacity-[0.1] rounded flex space-x-4">
                                        <InfoIcon style={{ fontSize: 30 }} className="text-purple-600" />
                                        <div>
                                          <Text className="font-black text-lg">Importante</Text>
                                          <Text className="font-medium">
                                            Envie o conteúdo em uma única linha, igual no exemplo a seguir
                                          </Text>
                                        </div>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>{MESSAGE_FILE_TEMPLATE}</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const parameters = new URLSearchParams({
  app: 'webservices',
  u: '${userApi}',
  p: 'xxxxxxxxxxx',
  to: '5511999888777',
  msg: '${MESSAGE_FILE_TEMPLATE}'
})

fetch(endpoint + '?' + parameters)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-full p-2 bg-emerald-700 rounded shadow">
                                          <div className="bg-black bg-opacity-[0.1] p-4">
                                            <div className="flex items-center space-x-2">
                                              <div className="w-[35px] h-[40px] bg-red-500 rounded flex justify-center items-center">
                                                <Text className="text-white font-black text-sm opacity-[0.5]">PDF</Text>
                                              </div>
                                              <div>
                                                <Text className="text-white">fatura.pdf</Text>
                                                <Text className="font-light text-white">PDF 13kb</Text>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem somente com arquivo',
                                      template: MESSAGE_FILE_TEMPLATE,
                                      httpMethod: 'GET'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>
                        <div className="mt-8">
                          <EndpointResponseExample />
                        </div>
                      </div>
                      {/*  */}

                      <div>
                        <div>
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-[#02b7fb]">POST</span>
                            <Text className="font-medium text-xl">
                              Envio com mensagem e arquivo
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div class="p-4 bg-purple-600 bg-opacity-[0.1] rounded flex space-x-4">
                                        <InfoIcon style={{ fontSize: 30 }} className="text-purple-600" />
                                        <div>
                                          <Text className="font-black text-lg">Importante</Text>
                                          <Text className="font-medium">
                                            Envie o conteúdo em uma única linha, igual no exemplo a seguir
                                          </Text>
                                        </div>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>{MESSAGE_FILE_AND_TEXT_TEMPLATE}</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const options = {
  method: 'POST',
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  },
  body: JSON.stringify({
    u: '${userApi}',
    p: 'xxxxxxxxxxx',
    to: '5511999888777',
    msg: '${MESSAGE_FILE_AND_TEXT_TEMPLATE}'
  })
}

fetch(endpoint, options)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-[70%] p-2 bg-emerald-700 rounded shadow mb-4">
                                          <Text className="text-white font-medium">
                                            Olá! Sua fatura já está disponível!
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                        <div className="opacity-0 w-full p-2 bg-emerald-700 rounded shadow">
                                          <div className="bg-black bg-opacity-[0.1] p-4">
                                            <div className="flex items-center space-x-2">
                                              <div className="w-[35px] h-[40px] bg-red-500 rounded flex justify-center items-center">
                                                <Text className="text-white font-black text-sm opacity-[0.5]">PDF</Text>
                                              </div>
                                              <div>
                                                <Text className="text-white">fatura.pdf</Text>
                                                <Text className="font-light text-white">PDF 13kb</Text>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem e arquivo',
                                      template: MESSAGE_FILE_AND_TEXT_TEMPLATE,
                                      httpMethod: 'POST'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>

                        {/* GET */}
                        <div className="mt-12">
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-purple-600" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-purple-600">GET</span>
                            <Text className="font-medium text-xl">
                              Envio com mensagem e arquivo
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div class="p-4 bg-purple-600 bg-opacity-[0.1] rounded flex space-x-4">
                                        <InfoIcon style={{ fontSize: 30 }} className="text-purple-600" />
                                        <div>
                                          <Text className="font-black text-lg">Importante</Text>
                                          <Text className="font-medium">
                                            Envie o conteúdo em uma única linha, igual no exemplo a seguir
                                          </Text>
                                        </div>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>{MESSAGE_FILE_AND_TEXT_TEMPLATE}</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const parameters = new URLSearchParams({
  app: 'webservices',
  u: '${userApi}',
  p: 'xxxxxxxxxxx',
  to: '5511999888777',
  msg: '${MESSAGE_FILE_AND_TEXT_TEMPLATE}'
})

fetch(endpoint + '?' + parameters)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-[70%] p-2 bg-emerald-700 rounded shadow mb-4">
                                          <Text className="text-white font-medium">
                                            Olá! Sua fatura já está disponível!
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                        <div className="opacity-0 w-full p-2 bg-emerald-700 rounded shadow">
                                          <div className="bg-black bg-opacity-[0.1] p-4">
                                            <div className="flex items-center space-x-2">
                                              <div className="w-[35px] h-[40px] bg-red-500 rounded flex justify-center items-center">
                                                <Text className="text-white font-black text-sm opacity-[0.5]">PDF</Text>
                                              </div>
                                              <div>
                                                <Text className="text-white">fatura.pdf</Text>
                                                <Text className="font-light text-white">PDF 13kb</Text>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem e arquivo',
                                      template: MESSAGE_FILE_AND_TEXT_TEMPLATE,
                                      httpMethod: 'GET'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>
                        <div className="mt-8">
                          <EndpointResponseExample />
                        </div>
                      </div>
                      {/*  */}

                      <div id="message-split">
                        <div>
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-[#02b7fb]">POST</span>
                            <Text className="font-medium text-xl">
                              Envio de mensagem com split
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div class="p-4 bg-purple-600 bg-opacity-[0.1] rounded flex space-x-4">
                                        <InfoIcon style={{ fontSize: 30 }} className="text-purple-600" />
                                        <div>
                                          <Text className="font-black text-lg">Importante</Text>
                                          <Text className="font-medium">
                                            Envie o conteúdo em uma única linha, igual no exemplo a seguir
                                          </Text>
                                        </div>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>{MESSAGE_FILE_AND_TEXT_TEMPLATE}</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const options = {
  method: 'POST',
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  },
  body: JSON.stringify({
    u: '${userApi}',
    p: 'xxxxxxxxxxx',
    to: '5511999888777',
    msg: '${MESSAGE_FILE_AND_TEXT_TEMPLATE}'
  })
}

fetch(endpoint, options)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-[70%] p-2 bg-emerald-700 rounded shadow mb-4">
                                          <Text className="text-white font-medium">
                                            Olá! Sua fatura já está disponível!
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                        <div className="opacity-0 w-[85%] p-2 bg-emerald-700 rounded shadow mb-4">
                                          <Text className="text-white font-medium">
                                            Caso já tenha efetuado o pagamento, favor desconsiderar!
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                        <div className="opacity-0 w-full p-2 bg-emerald-700 rounded shadow">
                                          <div className="bg-black bg-opacity-[0.1] p-4">
                                            <div className="flex items-center space-x-2">
                                              <div className="w-[35px] h-[40px] bg-red-500 rounded flex justify-center items-center">
                                                <Text className="text-white font-black text-sm opacity-[0.5]">PDF</Text>
                                              </div>
                                              <div>
                                                <Text className="text-white">fatura.pdf</Text>
                                                <Text className="font-light text-white">PDF 13kb</Text>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem split',
                                      template: MESSAGE_FILE_AND_TEXT_TEMPLATE,
                                      httpMethod: 'POST'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>

                        {/* GET */}
                        <div className="mt-12">
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-purple-600" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-purple-600">GET</span>
                            <Text className="font-medium text-xl">
                              Envio de mensagem com split
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div class="p-4 bg-purple-600 bg-opacity-[0.1] rounded flex space-x-4">
                                        <InfoIcon style={{ fontSize: 30 }} className="text-purple-600" />
                                        <div>
                                          <Text className="font-black text-lg">Importante</Text>
                                          <Text className="font-medium">
                                            Envie o conteúdo em uma única linha, igual no exemplo a seguir
                                          </Text>
                                        </div>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>{MESSAGE_SPLIT_TEMPLATE}</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const parameters = new URLSearchParams({
  app: 'webservices',
  u: '${userApi}',
  p: 'xxxxxxxxxxx',
  to: '5511999888777',
  msg: '${MESSAGE_SPLIT_TEMPLATE}'
})

fetch(endpoint + '?' + parameters)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-[70%] p-2 bg-emerald-700 rounded shadow mb-4">
                                          <Text className="text-white font-medium">
                                            Olá! Sua fatura já está disponível!
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                        <div className="opacity-0 w-[85%] p-2 bg-emerald-700 rounded shadow mb-4">
                                          <Text className="text-white font-medium">
                                            Caso já tenha efetuado o pagamento, favor desconsiderar!
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                        <div className="opacity-0 w-full p-2 bg-emerald-700 rounded shadow">
                                          <div className="bg-black bg-opacity-[0.1] p-4">
                                            <div className="flex items-center space-x-2">
                                              <div className="w-[35px] h-[40px] bg-red-500 rounded flex justify-center items-center">
                                                <Text className="text-white font-black text-sm opacity-[0.5]">PDF</Text>
                                              </div>
                                              <div>
                                                <Text className="text-white">fatura.pdf</Text>
                                                <Text className="font-light text-white">PDF 13kb</Text>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem split',
                                      template: MESSAGE_SPLIT_TEMPLATE,
                                      httpMethod: 'GET'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>
                        <div className="mt-8">
                          <EndpointResponseExample />
                        </div>
                      </div>
                      {/*  */}

                      <div id="message-pix">
                        <div>
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-[#02b7fb]">POST</span>
                            <Text className="font-medium text-xl">
                              Envio de mensagem PIX
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>{MESSAGE_PIX_TEMPLATE}</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const msg = \`${MESSAGE_PIX_TEMPLATE}\`
const options = {
  method: 'POST',
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  },
  body: JSON.stringify({
    u: '${userApi}',
    p: 'xxxxxxxxxxx',
    to: '5511999888777',
    msg
  })
}

fetch(endpoint, options)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-full p-2 bg-emerald-700 rounded shadow mb-4">
                                          <img src="https://i.imgur.com/tBv2bfG.png" className="rounded-md overflow-hidden mb-3" />
                                          <Text className="text-white font-bold">
                                            R$100,00
                                          </Text>
                                          <Text className="text-white font-bold">
                                            PLANO MEGA 300GB
                                          </Text>
                                          <Text className="text-white font-medium text-sm">
                                            https://hiperpay.app/pay/HUZQ29HWJRWJ
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem pix',
                                      template: MESSAGE_PIX_TEMPLATE,
                                      httpMethod: 'POST'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>

                        {/* GET */}
                        <div className="mt-12">
                          <div className="mb-2 flex items-center space-x-2">
                            <BookmarkIcon style={{ fontSize: 35 }} className="text-purple-600" />
                            <span className="flex justify-center w-[58px] font-black text-white bg-purple-600">GET</span>
                            <Text className="font-medium text-xl">
                              Envio de mensagem PIX
                            </Text>
                          </div>
                          <Paper elevation={5}>
                            <div>
                              <div className="p-4">
                                <div className="flex items-center space-x-2 mb-4">
                                  <Text className="font-black text-lg">
                                    Exemplo de requisição
                                  </Text>
                                </div>
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 md:col-span-8 space-y-4">
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Conteúdo a ser enviado
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code>{MESSAGE_PIX_TEMPLATE}</Code>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="mb-2">
                                        <Text>
                                          Exemplo em <span className="font-medium">Javascript</span>
                                        </Text>
                                      </div>
                                      <div className="rounded-md overflow-hidden">
                                        <Code language="javascript" wrapLongLines={false}>
{`const endpoint = 'https://myhs.app/sendMessage'
const msg = \`${MESSAGE_PIX_TEMPLATE}\`
const parameters = new URLSearchParams({
  app: 'webservices',
  u: '${userApi}',
  p: 'xxxxxxxxxxx',
  to: '5511999888777',
  msg
})

fetch(endpoint + '?' + parameters)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error(err))`}
                                        </Code>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative col-span-12 md:col-span-4">
                                    <div className="sticky top-[20px]">
                                      <div className="mb-2">
                                        <Text>
                                          Resultado
                                        </Text>
                                      </div>
                                      <PhoneDevice>
                                        <div className="opacity-0 w-full p-2 bg-emerald-700 rounded shadow mb-4">
                                          <img src="https://i.imgur.com/tBv2bfG.png" className="rounded-md overflow-hidden mb-3" />
                                          <Text className="text-white font-bold">
                                            R$100,00
                                          </Text>
                                          <Text className="text-white font-bold">
                                            PLANO MEGA 300GB
                                          </Text>
                                          <Text className="text-white font-medium text-sm">
                                            https://hiperpay.app/pay/HUZQ29HWJRWJ
                                          </Text>
                                          <div className="flex justify-end">
                                            <DoneAllIcon className="text-white" style={{ fontSize: 16 }} />
                                          </div>
                                        </div>
                                      </PhoneDevice>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-4 border-l-4 border-[#02b7fb] bg-black bg-opacity-[0.1]">
                                <button
                                  className="flex items-center justify-start w-full"
                                  onClick={() => {
                                    setOpenTestSendMessageModal(true)
                                    setApiTestData({
                                      title: 'Testar envio de mensagem pix',
                                      template: MESSAGE_PIX_TEMPLATE,
                                      httpMethod: 'GET'
                                    })
                                  }}
                                >
                                  <span className="flex items-center space-x-2">
                                    <ScienceIcon style={{ fontSize: 35 }} className="text-[#02b7fb]" />
                                    <Text className="font-medium text-xl" component="span">
                                      Testar envio
                                    </Text>
                                  </span>
                                  <span className="block ml-auto">
                                    <ChevronRightIcon style={{ fontSize: 35 }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </Paper>
                        </div>
                        <div className="mt-8">
                          <EndpointResponseExample />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutMain>
    </LayoutBase>
  )
}

function EndpointResponseExample() {
  return (
    <div>
      <div className="mb-4 flex items-center space-x-2">
        <Text className="font-medium text-xl">
          Respostas
        </Text>
        <span className="flex justify-center w-[58px] font-black text-white bg-[#02b7fb]">POST</span>
        <Text>e</Text>
        <span className="flex justify-center w-[58px] font-black text-white bg-purple-600">GET</span>
      </div>
      <div className="space-y-2">
        <div className="bg-gray-300 bg-opacity-[0.4] p-4 rounded">
          <div className="flex items-center space-x-2">
            <button>
              <Text component="span">
                <ExpandMoreIcon style={{ fontSize: 35 }} />
              </Text>
            </button>
            <Text className="font-black">
              201 Success
            </Text>
          </div>
          <div>
            <div className="rounded-md overflow-hidden">
              <Code>
{`{
  result: "success",
  status: 201,
  date: Date,
  message: "mensagem adicionada a fila"
}`}
              </Code>
            </div>
          </div>
        </div>
        <div className="bg-gray-300 bg-opacity-[0.4] p-4 rounded">
          <div className="flex items-center space-x-2">
            <button>
              <Text component="span">
                <ExpandMoreIcon style={{ fontSize: 35 }} />
              </Text>
            </button>
            <Text className="font-black">
              400 Bad request
            </Text>
          </div>
          <div>
            <div className="rounded-md overflow-hidden">
              <Code>
{`{
  result: "error",
  status: 400,
  date: Date,
  message: "<error>"
}`}
              </Code>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
