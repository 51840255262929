function getDataFromSendFileMetaTag(message) {
  let regexSendFile = '%SendFile%(.+?)%/SendFile%'
  let regexFilename = '%Filename%(.+?)%/Filename%'
  let regexUrl = '%Url%(.+?)%/Url%'
  let regexApp = '%App%(.+?)%/App%'

  let tagSendFile = message.match(regexSendFile)
  let tagFilename
  let tagUrl
  let tagApp

  let filename, app, url, type

  if (tagSendFile) {
    tagFilename = tagSendFile[0].match(regexFilename)
    tagUrl = tagSendFile[0].match(regexUrl)
    tagApp = tagSendFile[0].match(regexApp)

    if (tagFilename) {
      filename = tagFilename[0].replace('%Filename%', '')
      filename = filename.replace('%/Filename%', '')
    }
    if (tagApp) {
      app = tagApp[0].replace('%App%', '')
      app = app.replace('%/App%', '')
    }
    if (tagUrl) {
      url = tagUrl[0].replace('%Url%', '')
      url = url.replace('%/Url%', '')

      type = url.substring(url.lastIndexOf('.') + 1)

      if ((app && app === 'IXC') || (app && app === 'MKAUTH')) {
        if (url.substr(-5) === '.html') {
          url = url.substring(0, url.length - 5)
        }
      }
    }
    if (url) {
      return {
        filename: filename,
        url: url,
        type: type,
        app: app,
      }
    }
  }
  return undefined
}

function clearMetaTagMessage(message) {
  return message.replace(/(%SendFile%)(.*)(%\/SendFile%)/g, '')
}

function clearMetaTagPix(message, value = '') {
  return message.replace(/(%Pix%)(.|\r\n|\r|\n)*(%\/Pix%)/g, value)
}

function getFileFromMessage(message) {
  return getDataFromSendFileMetaTag(message)
}

function clearMessage(message) {
  return clearMetaTagMessage(message)
}

function formatMessage(message, { link = '' } = {}) {
  let msg = clearMetaTagMessage(message)
  msg = clearMetaTagPix(msg, link)

  return msg
}

export default {
  getFileFromMessage,
  clearMessage,
  formatMessage
}
