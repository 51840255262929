import React, { useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import delay from 'delay'
import anime from 'animejs'
import { useNavigate } from 'react-router-dom'
import { Button, Collapse, Paper, useTheme } from '@mui/material'
import PasswordIcon from '@mui/icons-material/Password'
import ErrorIcon from '@mui/icons-material/Error'
import { AuthContext } from '../../contexts/Auth'
import Text from '../Text'
import Logo from '../Logo'

export default function SigninForm() {
  const { signin } = useContext(AuthContext)
  const navigator = useNavigate()
  const theme = useTheme()
  const shapesContainerRef = useRef()
  const formContainerRef = useRef()
  const welcomeMessageRef = useRef()

  const [api, setApi] = useState('')
  const [secret, setSecret] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loginSuccess, setLoginSuccess] = useState(false)

  const handleSignin = async () => {
    try {
      setErrorMessage('')
      setIsLoading(true)
      await delay(800)
      await signin(api, secret)
      setIsLoading(false)
      setLoginSuccess(true)
      handleShapesAnimationReverse()
      handleFormAnimationReverse()
      await delay(2000)
      handleWelcomeAnimation()
      await delay(2000)
      handleWelcomeAnimationReverse()
      await delay(1000)
      navigator('/')
    } catch (error) {
      console.error(error.response)
      setIsLoading(false)
      setLoginSuccess(false)

      if(error?.response?.data?.error === 'Não foi possível encontrar a instância') {
        setErrorMessage('Credenciais inválidas')
        return
      }

      setErrorMessage('Erro ao efetuar login')
    }
  }

  const handleShapesAnimation = () => {
    anime({
      targets: shapesContainerRef.current.children,
      translateY: [-500, 0],
      opacity: [0, 1],
      delay: anime.stagger(100),
      easing: 'easeInOutExpo'
    })
  }

  const handleShapesAnimationReverse = () => {
    anime({
      targets: shapesContainerRef.current.children,
      translateY: [0, -500],
      opacity: [1, 0],
      delay: anime.stagger(100),
      easing: 'easeInOutExpo'
    })
  }

  const handleFormAnimation = async () => {
    await delay(500)
    anime({
      targets: formContainerRef.current.children,
      translateY: [100, 0],
      opacity: [0, 1],
      scale: [0.95, 1],
      delay: anime.stagger(50),
      easing: 'easeInOutExpo'
    })
  }

  const handleFormAnimationReverse = async () => {
    await delay(500)
    anime({
      targets: formContainerRef.current.children,
      translateY: [0, 100],
      opacity: [1, 0],
      scale: [1, 0.95],
      delay: anime.stagger(50),
      easing: 'easeInOutExpo'
    })
  }

  const handleWelcomeAnimation = async () => {
    anime({
      targets: welcomeMessageRef.current.children,
      translateY: [100, 0],
      opacity: [0, 1],
      scale: [0.95, 1],
      delay: anime.stagger(50),
      easing: 'easeInOutExpo'
    })
  }

  const handleWelcomeAnimationReverse = async () => {
    anime({
      targets: welcomeMessageRef.current.children,
      translateY: [0, 100],
      opacity: [1, 0],
      scale: [1, 0.95],
      delay: anime.stagger(50),
      easing: 'easeInOutExpo'
    })
  }

  useEffect(() => {
    handleShapesAnimation()
    handleFormAnimation()
  }, [])

  return (
    <div
      className="min-h-full"
      style={{ backgroundColor: theme.palette.background.default }}
    >
      {/* welcome message */}
      <div className={clsx(loginSuccess ? '' : 'hidden pointer-events-none', 'absolute z-[999] w-full h-full top-0 left-0 flex justify-center items-center')}>
        <div ref={welcomeMessageRef} className="text-5xl">
          <span className={clsx(theme.isDark ? "text-white" : "", "opacity-0 inline-block")}>
            Bem vindo
          </span>
        </div>
      </div>
      {/* shape */}
      <div ref={shapesContainerRef}>
        {/* shape color */}
        <div className="opacity-0">
          <div className="absolute top-0 left-0 h-[300px] w-full" style={{ backgroundColor: theme.palette.primary.main }} />
        </div>
        <div className="opacity-0">
          <div
            className={clsx('absolute top-0 left-0 h-[400px] w-full opacity-[0.05]', {
              'bg-white': theme.isDark,
              'bg-gray-800': !theme.isDark
            })}
          />
        </div>
        {/* shape */}
        <div className="opacity-0">
          <div
            className={clsx('absolute top-0 left-0 h-[500px] w-full opacity-[0.05]', {
              'bg-white': theme.isDark,
              'bg-gray-800': !theme.isDark
            })}
          />
        </div>
      </div>
      <div ref={formContainerRef} className="relative w-full py-20">
        <div className="opacity-0 max-w-[550px] mx-auto mb-4 px-4 md:px-0">
          <Logo className="w-[220px]" white />
          <Text className="font-bold text-white">Notificações Inteligentes</Text>
          <Text className="font-bold text-white text-xs">Volare Family</Text>
        </div>
        <div className="opacity-0 max-w-[550px] w-full mx-auto flex justify-center">
          <Paper className="w-full">
            <div className="px-3 md:px-12 py-14">
              <div className="mb-4">
                <PasswordIcon style={{ fontSize: 50, color: theme.palette.primary.main }} />
                <Text className="text-2xl font-medium">Credenciais</Text>
                <Text className="font-medium">Digite sua api e secret para acessar o painel</Text>
              </div>
              <div>
                <Text className="text-lg">Api</Text>
                <input
                  className="border-b-2 w-full h-[50px] outline-none px-6 text-lg font-medium"
                  placeholder="api-xxxxxxxxxxx"
                  style={{
                    backgroundColor: theme.palette.background.paper
                  }}
                  onChange={e => setApi(e.target.value)}
                  value={api}
                />
              </div>
              <div className="mt-4">
                <Text className="text-lg">Secret</Text>
                <input
                  className="border-b-2 w-full h-[50px] outline-none px-6 text-lg font-medium"
                  type="password"
                  placeholder="****************"
                  style={{
                    backgroundColor: theme.palette.background.paper
                  }}
                  onChange={e => setSecret(e.target.value)}
                  value={secret}
                />
              </div>
              <Collapse in={!!errorMessage} timeout="auto" unmountOnExit>
                <div className="mt-4 p-1 bg-red-600 flex items-center space-x-2">
                  <ErrorIcon className="text-white" />
                  <Text className="text-white font-bold">{errorMessage}</Text>
                </div>
              </Collapse>
              <div className="mt-12">
                <Button onClick={handleSignin} disabled={isLoading || loginSuccess} className="h-[50px]" variant="contained" fullWidth size="large">
                  <Text className="text-white font-black">
                    {
                      isLoading
                      ? 'VALIDANDO CREDENCIAIS'
                      : 'ENTRAR'
                    }
                  </Text>
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  )
}
