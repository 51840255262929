export const MESSAGE_PIX_TEMPLATE = `*R$100,00*
*PLANO MEGA 300GB*
%Pix%
  %PixTitle%
    PLANO MEGA 300GB
  %/PixTitle%
  %PixSubtitle%
    Liberação imediata no pix
  %/PixSubtitle%
  %PixCode%
    00020101021226940014BR.GOV.BCB.PIX2572...
  %/PixCode%
  %PixQrCode%
    data:image\/png;base64,iVBORw0KGgoAAAANS...
  %/PixQrCode%
  %PixPrice%
    R$80,90
  %/PixPrice%
  %PixLogoUrl%
    https://i.imgur.com/zdcyEw7.png
  %/PixLogoUrl%
  %PixInvoiceUrl%
    https://meusite.com.br/invoice/Dc3RCoIwFAbg9
  %/PixInvoiceUrl%
  %PixInfo%
    Pagamento com pix a liberação é realizada imediatamente
  %/PixInfo%
  %PixPrimaryColor%
    #03adec
  %/PixPrimaryColor%
  %PixThumbnail%
    https://i.imgur.com/tBv2bfG.png
  %/PixThumbnail%
%/Pix%`

export const MESSAGE_SIMPLE_TEMPLATE = 'Olá! Sua fatura vence nos próximos 5 dias.'

export const MESSAGE_FILE_TEMPLATE = '%SendFile%%Filename%fatura.pdf%/Filename%%Url%https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf%/Url%%/SendFile%'

export const MESSAGE_FILE_AND_TEXT_TEMPLATE = 'Olá! Sua fatura já está disponível!%SendFile%%Filename%fatura.pdf%/Filename%%Url%https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf%/Url%%/SendFile%'

export const MESSAGE_SPLIT_TEMPLATE = '%NewLine%Olá! Sua fatura já está disponível!%/NewLine%%NewLine%Caso já tenha efetuado o pagamento, favor desconsiderar!%/NewLine%%NewLine%%SendFile%%Filename%fatura.pdf%/Filename%%Url%https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf%/Url%%/SendFile%%/NewLine%'