import React from 'react'
import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText } from '@mui/material'
import countryCodesLib from '../../libs/countryCodes'

function CountryCodesModal({ open, onClose, onSelect, selectedValue }) {
  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleSelect = (value) => {
    onSelect(value)
  }

  return (
    <Dialog onClose={handleClose} maxWidth="xs" hideBackdrop fullWidth open={open} style={{height: '600px' }}>
      <DialogTitle>Selecione o país</DialogTitle>
      <List sx={{ pt: 0 }}>
        {countryCodesLib.codes.map((country) => (
          <ListItem disableGutters key={country.code}>
            <ListItemButton onClick={() => handleSelect(country)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: '#87878733' }}>
                  {country.flag}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${country.code} ${country.dial_code}`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default CountryCodesModal
