import { useTheme } from '@mui/material'

export default function Logo({ className = '', white = false }) {
  const theme = useTheme()

  const logoUrl = white
    ? '/assets/logo-white.svg'
    : theme.isDark
      ? '/assets/logo-primary-text-white.svg'
      : '/assets/logo-primary.svg'

  return <img src={logoUrl} className={className} />
}
