import React from 'react'
import { useTheme } from '@mui/material'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

function Code({ children, language = 'markdown', wrapLongLines = true }) {
  const theme = useTheme()
  return (
    <SyntaxHighlighter
      language={language}
      style={atomOneDark}
      showLineNumbers
      wrapLines={true}
      wrapLongLines={wrapLongLines}
      customStyle={{
        backgroundColor: theme.isDark ? theme.palette.background.paper : theme.palette.grey[900],
        fontWeight: 700
      }}
    >{children}</SyntaxHighlighter>
  )
}

export default Code
