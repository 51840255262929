import React from 'react'
import clsx from 'clsx'
import { useTheme } from '@mui/material'
// import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle'
// import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LayoutMain from '../layouts/Main'
import LayoutBase from '../layouts/Base'
import DeviceConnection from '../components/DeviceConnection'

// import { Button, Paper, Snackbar } from '@mui/material'
// import Text from '../components/Text'

export default function HomePage() {
  const theme = useTheme()

  return (
    <LayoutBase>
      <LayoutMain>
        <div className="relative w-full py-14">
          <div
            className={clsx('absolute top-0 left-0 w-full h-[400px] ', {
              'bg-opacity-[0.05] bg-white': theme.isDark,
              'bg-opacity-[0.05] bg-gray-500': !theme.isDark
            })}
          />
          <div
            className={clsx('absolute top-0 left-0 w-full h-[550px]', {
              'bg-opacity-[0.05] bg-white': theme.isDark,
              'bg-opacity-[0.05] bg-gray-500': !theme.isDark
            })}
          />
          <div className="relative py-12 md:py-32">
            <div className="mx-auto w-full md:max-w-[1300px] px-2 md:px-12">
              {/* <div className="mb-4">
                <Text className="font-light text-xl">
                  CANAIS
                </Text>
                <Text>
                  Canais disponível para conexão
                </Text>
              </div>
              <div className="mb-20 grid md:grid-cols-3 gap-3">
                <div>
                  <Paper elevation={1} className="overflow-hidden">
                    <div className="p-8">
                      <div className="grid grid-cols-12">
                        <div className="col-span-2">
                          <img className="w-[40px]" src="https://i.imgur.com/ddpdu08.png" />
                        </div>
                        <div className="col-span-10">
                          <Text className="font-medium">Whatsapp QR Code</Text>
                          <Text className="font-light text-sm">Integração não oficial via QR Code</Text>
                          <div className="mt-6">
                            <div className="flex space-x-2">
                              <CheckCircleIcon className="text-[#02b7fb]" />
                              <Text className="font-black text-[#02b7fb]">Conectado</Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-400 bg-opacity-5">
                      <a className="font-medium text-sm flex justify-between w-full px-8 py-5" href="/channels/whatsapp-qr-code">
                        <span>GERENCIAR</span>
                        <ChevronRightIcon />
                      </a>
                    </div>
                  </Paper>
                </div>
                <div>
                  <Paper elevation={1} className="overflow-hidden">
                    <div className="p-8">
                      <div className="grid grid-cols-12">
                        <div className="col-span-2">
                          <img className="w-[40px]" src="https://i.imgur.com/ddpdu08.png" />
                        </div>
                        <div className="col-span-10">
                          <div className="flex space-x-2 items-center">
                            <Text className="font-medium">Whatsapp Business Api</Text>
                            <span className="block px-1 py-[1px] bg-[#02b7fb] text-xs font-black text-white">NOVO</span>
                          </div>
                          <Text className="font-light text-sm">Integração oficial via api</Text>
                          <div className="mt-6">
                            <div className="flex space-x-2">
                              <DoNotDisturbOnIcon className="text-gray-500" />
                              <Text>Desconectado</Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-400 bg-opacity-5">
                      <a className="font-medium text-sm flex justify-between w-full px-8 py-5" href="/channels/whatsapp-business-api">
                        <span>GERENCIAR</span>
                        <ChevronRightIcon />
                      </a>
                    </div>
                  </Paper>
                </div>
                <div>
                  <Paper elevation={1} className="overflow-hidden">
                    <div className="p-8">
                      <div className="grid grid-cols-12">
                        <div className="col-span-2">
                          <img className="w-[40px]" src="https://i.imgur.com/g6snMLT.png" />
                        </div>
                        <div className="col-span-10">
                          <div className="flex space-x-2 items-center">
                            <Text className="font-medium">Telegram</Text>
                            <span className="block px-1 py-[1px] bg-[#02b7fb] text-xs font-black text-white">BREVE</span>
                          </div>
                          <Text className="font-light text-sm">Integração oficial via api</Text>
                          <div className="mt-6">
                            <div className="flex space-x-2">
                              <DoNotDisturbOnIcon className="text-gray-500" />
                              <Text>Desconectado</Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-400 bg-opacity-5">
                      <a className="font-medium text-sm flex justify-between w-full px-8 py-5" href="/channels/telegram">
                        <span>GERENCIAR</span>
                        <ChevronRightIcon />
                      </a>
                    </div>
                  </Paper>
                </div>
              </div> */}
              <DeviceConnection />
            </div>
          </div>
        </div>
      </LayoutMain>
    </LayoutBase>
  )
}
