import React, { createContext, useEffect } from 'react'
import hipersendService from '../../services/hipersend'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const logout = () => {
    window.localStorage.setItem('api', '')
    window.localStorage.setItem('secret', '')
    window.location.replace('/signin')
  }

  const signin = async (api, secret) => {
    try {
      await hipersendService.notificacoesInteligentes.getInformations(api, secret)
      window.localStorage.setItem('api', api)
      window.localStorage.setItem('secret', secret)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const handleIsLoggedIn = () => {
    const api = window.localStorage.getItem('api')
    const secret = window.localStorage.getItem('secret')

    const isSigninPage = window.location.pathname === '/signin'

    if((!api || !secret) && !isSigninPage) {
      logout()
    }
  }

  useEffect(() => {
    handleIsLoggedIn()
  }, [])

  return (
    <AuthContext.Provider value={{ logout, signin }}>
      {children}
    </AuthContext.Provider>
  )
}
