import React, { useContext, useState } from 'react'
import clsx from 'clsx'
import delay from 'delay'
import {
  Button,
  Collapse,
  Paper,
  useTheme } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import ModeCommentIcon from '@mui/icons-material/ModeComment'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import CloseIcon from '@mui/icons-material/Close'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import AddIcon from '@mui/icons-material/Add'
import hipersendService from '../../services/hipersend'
import countryCodesLib from '../../libs/countryCodes'
import { ApiDataWrapperContext } from '../../contexts/ApiDataWrapper'
import Text from '../Text'
import TextFieldCustom from '../TextFieldCustom'
import CountryCodesModal from '../CountryCodesModal'

const COUNTRY_CODE_DEFAULT = countryCodesLib.codes.find(country => country.code === 'BR')

export default function MessageSender() {
  const { api, secret } = useContext(ApiDataWrapperContext)
  const theme = useTheme()
  const [isCountryCodesModalOpen, setIsCountryCodesModalOpen] = useState(false)
  const [country, setCountry] = useState(COUNTRY_CODE_DEFAULT)
  const [numbers, setNumbers] = useState([])
  const [rawNumber, setRawNumber] = useState('')
  const [fileUrl, setFileUrl] = useState('')
  const [message, setMessage] = useState('')
  const [sendingMessages, setSendingMessages] = useState(false)
  const [messagesSent, setMessagesSent] = useState(false)
  const [messageError, setMessageError] = useState('')

  const addNumbers = () => {
    if(!rawNumber) return
    const newNumbers = rawNumber
      .split(',')
      .map(number => number.trim())
      .map(number => country.dial_code + number)

    setNumbers([...numbers, ...newNumbers])
    setRawNumber('')
  }

  const removeNumber = index => {
    const _numbers = numbers.slice()
    _numbers.splice(index, 1)
    setNumbers(_numbers)
  }

  const handleSubmitMessage = async () => {
    try {
      setMessageError('')
      setSendingMessages(true)
      for(const number of numbers) {
        await hipersendService.notificacoesInteligentes.sendMessage(api, secret, {
          message,
          fileUrl,
          phone: number
        })
        await delay(300)
      }
      setMessagesSent(true)
      setSendingMessages(false)
    } catch (error) {
      console.error(error)
      if(error?.response?.data?.error === 'QR CODE NÃO ESCANEADO') {
        setMessageError('QR code da api não scaneado')
      }
      setSendingMessages(false)
    }
  }

  return (
    <>
      <CountryCodesModal
        open={isCountryCodesModalOpen}
        onClose={() => setIsCountryCodesModalOpen(false)}
        onSelect={(country) => {
          setIsCountryCodesModalOpen(false)
          setCountry(country)
        }}
      />
      <Snackbar
        open={messagesSent}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setMessagesSent(false)}
        message={numbers.length > 1 ? `${numbers.length} mensagens adicionadas na fila` : `${numbers.length} mensagem adicionada na fila`}
      />
      <div className="relative mb-32">
        <div
          className={clsx('absolute top-0 left-0 w-full h-[400px] ', {
            'bg-opacity-[0.02] bg-white': theme.isDark,
            'bg-opacity-[0.05] bg-gray-800': !theme.isDark
          })}
        />
        <div
          className={clsx('absolute top-0 left-0 w-full h-[550px]', {
            'bg-opacity-[0.02] bg-white': theme.isDark,
            'bg-opacity-[0.05] bg-gray-800': !theme.isDark
          })}
        />
        <div className="relative max-w-[750px] w-full mx-auto py-12 md:py-32">
          <div className="flex items-center space-x-2">
            <ModeCommentIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
            <Text className="text-lg md:text-xl font-medium">
              Enviar mensagem
            </Text>
          </div>
          <div className="mt-4">
            <Paper elevation={5}>
              <Paper elevation={0}>
                <div className="w-full p-8 space-y-12">
                  <div>
                    <Text>
                      Informe os números que deseja enviar mensagem
                    </Text>
                    <div className="mt-1">
                      <div className="md:flex items-center md:space-x-4">
                        <div className="table">
                          <button
                            className="w-[100px] h-[56px] border-2 rounded text-2xl px-2 justify-center flex items-center space-x-2"
                            onClick={() => setIsCountryCodesModalOpen(!isCountryCodesModalOpen)}
                          >
                            <span>{country.flag}</span>
                            <span className="text-lg block">{country.dial_code}</span>
                          </button>
                        </div>
                        <TextFieldCustom
                          size="large"
                          value={rawNumber}
                          onChange={e => setRawNumber(e.target.value)}
                          placeholder="11999999999,11888888888,11777777777"
                        />
                        <Button startIcon={<AddIcon />} onClick={addNumbers}>
                          ADICIONAR
                        </Button>
                      </div>
                      <div className="mt-4">
                        <div className={clsx('py-4 px-6 bg-opacity-[0.05] rounded flex space-x-4', { 'bg-white': theme.isDark, 'bg-gray-800': !theme.isDark })}>
                          <div className="mt-2">
                            <TipsAndUpdatesIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
                          </div>
                          <Text>
                            Separe os números por vírgular ao enviar mensagem para mais de um contato. <br />
                            Exemplo: 11999999999, 11888888888, 11777777777, ...
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Collapse in={numbers.length > 0} timeout="auto" unmountOnExit>
                    <div>
                      <Text>
                        Números
                      </Text>
                      <Text>
                        {numbers.length} números informados
                      </Text>
                      <div className="mt-3">
                        <ul className="flex flex-wrap">
                          {numbers.map((number, index) => (
                            <li key={index} className="table mr-2 my-2">
                              <div className="flex items-center space-x-1">
                                <SmartphoneIcon />
                                <Text>{number}</Text>
                                <button onClick={() => removeNumber(index)}>
                                  <CloseIcon className="opacity-[0.5]" />
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Collapse>
                  <div>
                    <Text>
                      URL do arquivo
                    </Text>
                    <div className="mt-1">
                      <TextFieldCustom size="large" value={fileUrl} onChange={e => setFileUrl(e.target.value)} />
                      <div className="mt-4">
                        <div className={clsx('py-4 px-6 bg-white bg-opacity-[0.05] rounded items-center flex space-x-4')}>
                          <Text>
                            Certifique-se de que a url do arquivo informado é acessível publicamente.
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Text>
                      Mensagem
                    </Text>
                    <div className="mt-1">
                      <TextFieldCustom
                        multiline
                        rows={15}
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      />
                    </div>
                  </div>
                  {
                    messageError &&
                    <div className="p-1 bg-red-600 flex items-center space-x-2">
                      <ReportProblemIcon className="text-white" />
                      <Text className="text-white font-bold">{messageError}</Text>
                    </div>
                  }
                  <div>
                    <Button disabled={sendingMessages} color="default" onClick={handleSubmitMessage}>
                      {
                        sendingMessages
                        ? 'ENVIANDO'
                        : 'ENVIAR MENSAGEM'
                      }
                    </Button>
                  </div>
                </div>
              </Paper>
            </Paper>
          </div>
        </div>
      </div>
    </>
  )
}
