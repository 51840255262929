import React, { useState } from 'react'
import axios from 'axios'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import countryCodesLib from '../../libs/countryCodes'
import { API_HS_NI_ENDPOINT } from '../../services/hipersend'
import Text from '../Text'
import CountryCodesModal from '../CountryCodesModal'
import Code from '../Code'
import TextFieldCustom from '../TextFieldCustom'

const COUNTRY_CODE_DEFAULT = countryCodesLib.codes.find(country => country.code === 'BR')

function TestSendMessageModal({ open, onClose, template = '', title, httpMethod = 'POST' }) {
  const [country, setCountry] = useState(COUNTRY_CODE_DEFAULT)
  const [isCountryCodesModalOpen, setIsCountryCodesModalOpen] = useState(false)
  const [phone, setPhone] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleClose = () => {
    setErrorMessage('')
    if(typeof onClose === 'function') {
      onClose()
    }
  }

  const handleSendMessage = async () => {
    try {
      setErrorMessage('')
      const request = httpMethod === 'POST' ? handleSendMessagePost : handleSendMessageGet
      const response = await request()
    } catch (error) {
      console.error(error)
      const data = error.response.data
      if(data.message === 'QR CODE NÃO ESCANEADO') {
        setErrorMessage('Qr code da api não escaneado')
        return
      }
      setErrorMessage(data.message)
    }
  }

  const handleSendMessagePost = () => {
    return axios.post(`${API_HS_NI_ENDPOINT}/sendMessage`, getData())
  }

  const handleSendMessageGet = () => {
    const data = getData()
    const parameters = new URLSearchParams({
      app: 'webservices',
      ...data
    })
    return axios.get(`${API_HS_NI_ENDPOINT}/sendMessage?${parameters}`)
  }

  const getData = () => {
    const u = window.localStorage.getItem('api')
    const p = window.localStorage.getItem('secret')
    const to = country.dial_code + phone
    return {
      u,
      p,
      to,
      msg: template
    }
  }

  return (
    <>
      <CountryCodesModal
        open={isCountryCodesModalOpen}
        onClose={() => setIsCountryCodesModalOpen(false)}
        onSelect={(country) => {
          setIsCountryCodesModalOpen(false)
          setCountry(country)
        }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para realizar o teste informe o número do destinário para receber a mensagem de teste.
          </DialogContentText>
          <div className="mt-6">
            <Text>Testando envio método</Text>
            <Text className="font-black">{httpMethod}</Text>
          </div>
          <div className="mt-6">
            <Text>Template</Text>
            <div className="max-h-[200px] overflow-scroll">
              <Code wrapLongLines={false}>{template}</Code>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center space-x-2">
              <div className="table">
                <button
                  className="w-[100px] h-[56px] border-2 rounded text-2xl px-2 justify-center flex items-center space-x-2"
                  onClick={() => setIsCountryCodesModalOpen(!isCountryCodesModalOpen)}
                  type="button"
                >
                  <span>{country.flag}</span>
                  <span className="text-lg block">{country.dial_code}</span>
                </button>
              </div>
              <TextFieldCustom
                size="large"
                label="Número com DDD"
                placeholder="11999999999"
                onChange={e => setPhone(e.target.value)}
              />
            </div>
          </div>
          {
            errorMessage &&
            <div className="mt-2 p-2 bg-red-600">
              <Text className="font-black text-white">{errorMessage}</Text>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">Fechar</Button>
          <Button type="submit" onClick={handleSendMessage}>Enviar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TestSendMessageModal
