import React, { useEffect, useRef, useState } from 'react'
import anime from 'animejs'
import delay from 'delay'
import { useInViewport } from 'react-in-viewport'
import VolumeMuteIcon from '@mui/icons-material/VolumeMute'
import TelegramIcon from '@mui/icons-material/Telegram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import Battery60Icon from '@mui/icons-material/Battery60'
import Text from '../Text'

const PhoneDevice = ({ children }) => {
  const containerRef = useRef()
  const messagesRef = useRef()
  const [countMinutes, setCountMinutes] = useState(0)
  const [time, setTime] = useState(new Date())
  const { inViewport } = useInViewport(containerRef, {}, { disconnectOnLeave: false }, {})
  const [alreadyAnimated, setAlreadyAnimated] = useState(false)

  const handleAnimation = async () => {
    await delay(200)
    anime({
      targets: messagesRef.current.children,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: anime.stagger(100)
    })
    setAlreadyAnimated(true)
  }
  const style = { backgroundColor: 'rgb(42, 43, 46)' }

  useEffect(() => {
    const interval = setInterval(() => {
      setCountMinutes(countMinutes + 1)
      setTime(new Date())
    }, 1000)

    return () => clearInterval(interval)
  }, [countMinutes])

  useEffect(() => {
    if(inViewport && !alreadyAnimated) {
      handleAnimation()
    }
  }, [inViewport])

  return (
    <div style={style} ref={containerRef} className="relative w-full h-[700px] rounded-[20px] flex items-center justify-center">
      <div style={style} className="absolute w-[5px] h-[80px] top-[100px] -left-[3px]" />
      <div style={style} className="absolute w-[35%] h-[20px] rounded-b-full top-[5px] left-1/2 -translate-x-1/2">
        <div className="relative w-full h-full">
          <div className="w-[40%] h-[7px] bg-opacity-[0.2] bg-white -mt-[2px] absolute -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2 rounded-full" />
        </div>
      </div>
      <div
        className="w-[97%] h-[96%] border border-[#7f7f7f34] bg-gray-800 rounded-[12px] overflow-hidden"
      >
        <div className="bg-gray-800">
          <div className="p-4 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Text className="text-white font-black">{time.getHours()}:{time.getMinutes()}</Text>
              <WhatsAppIcon className="text-white" />
              <TelegramIcon className="text-white" />
            </div>
            <div className="flex items-center space-x-2">
              <VolumeMuteIcon className="text-white" />
              <SignalCellularAltIcon className="text-white" />
              <Text className="font-medium text-white">5G</Text>
              <Battery60Icon className="transform rotate-90 text-white" />
            </div>
          </div>
        </div>
        <div className="px-4 py-12" ref={messagesRef}>
          {children}
        </div>
        <div className="w-[25%] h-[7px] bg-white rounded-full opacity-[0.2] absolute -translate-x-1/2 left-1/2 bottom-7" />
      </div>
    </div>
  )
}

export default PhoneDevice